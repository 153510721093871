//mapsandguides/guides
var starttab = $("[data-starttab]").data("starttab");
if (starttab !== "") {
    window.location.hash = starttab;
}
var hashChange = function () {
    if (window.location.hash) {
        var targetTab = window.location.hash;
        $("#pages > div").addClass("hidden");
        $(".contentNav ul li").removeClass("active");
        $(".contentNav ul li a[href$='" + targetTab + "']").parent().addClass("active");
        $("#pages > div." + targetTab.substr(1)).removeClass("hidden");
    }
}
$(function () {
    hashChange();

    $(window).bind('hashchange', function () {
        hashChange();
    });

    $(".contentNav ul li a").click(function () {
        var hash = $(this).attr("href");
        window.location.hash = hash;

        return false;
    });


    $('#Order_Country').change(function () {
        var country = $(this).val();
        if (country != 'US' && country != 'CA') {
            $('select#Order_State').hide();
            $('[data-valmsg-for="Order.State"]').hide();
            $('label[for="Order_State"]').parent().hide();
            $('select#Order_State').attr("disabled", "disabled");
            $('#Order_State_Hidden').removeAttr("disabled");
        }
        else {
            $('select#Order_State').show();
            $('label[for="Order_State"]').parent().show();
            $('[data-valmsg-for="Order.State"]').show();
            $('select#Order_State').removeAttr("disabled");
            $('#Order_State_Hidden').attr("disabled", "disabled");
        }
    });
});